import HTTPException  from "$lib/exceptions/http";
import * as Sentry from "@sentry/browser";

export type HTTPErrorEvent = { 
    url: string;
    status: number;
    message: string;
    user: string;    
}

export type UncaughtErrorEvent = { 
    url: string;
    error: Error;  
}


export const reportHTTPExceptionEvent = (event: HTTPErrorEvent) => {

    if(event.status < 500) {
        return; 
    }

    Sentry.withScope(scope => {
        scope.setUser({ email: event.user });
        scope.setTag("http.status_code", event.status);
        scope.setTag("error.type", "HTTPException");
        scope.setTag("error.handled", true);
        scope.setExtra("url", event.url);
        Sentry.captureException(new HTTPException(event.status, event.message));
    });

}

export const reportUnhandledExceptionEvent = (event: UncaughtErrorEvent) => {
    Sentry.withScope(scope => {
        scope.setTag("error.type", event.error.name);
        scope.setTag("error.handled", false); 
        scope.setExtra("url", event.url); 
        Sentry.captureException(event.error);
    });

}