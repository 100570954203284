import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/ccpa": [3],
		"/contact-us": [4],
		"/dnc": [~5],
		"/opt-in-done": [7],
		"/opt-in": [~6],
		"/privacy-notice": [8],
		"/privacy-notice/de": [9],
		"/privacy-notice/en": [10],
		"/privacy-notice/es": [11],
		"/privacy-notice/fr": [12],
		"/privacy-notice/it": [13],
		"/privacy-notice/ja": [14],
		"/privacy-notice/ko": [15],
		"/privacy-notice/nl": [16],
		"/privacy-policy": [~17],
		"/resubscribed": [18],
		"/unsubscribed": [20],
		"/unsubscribe": [~19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';