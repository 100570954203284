
import { reportUnhandledExceptionEvent } from '$lib/instrumentation/errors';



export const handleError = ({ error, event }) => {
    reportUnhandledExceptionEvent({
        url: event.url.toString(),
        error,
    }); 
   
    return {
      message: error?.message ?? "Something went wrong"  ,
    };
  }